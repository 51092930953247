import React from "react"
import logo from "../images/logo-header-black.png"

const navigation = {
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/forexanalytix',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 448 512" {...props} >
          <path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm297.1 84L257.3 234.6L379.4 396h-95.6L209 298.1L123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5l78.2-89.5zm-37.8 251.6L153.4 142.9h-28.3l171.8 224.7h26.3z" />
        </svg>
      ),
    },
    {
      name: 'Youtube',
      href: 'https://www.youtube.com/forexanalytix',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/forexanalytix?igsh=bXIyNWNoa203MGJj&utm_source=qr",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/forexanalytix',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/forexanalytix",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 50 50" {...props}>
          <path
            fillRule="evenodd"
            d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

const menuItems = [
  {label: 'Forex Analytix', url: 'https://www.forexanalytix.com/'},
  {label: 'About Us', url: '/#about-us'},
  {label: 'Mentors', url: '/#mentors'},
  {label: 'Testimonials', url: '/#testimonials'},
  {label: 'Privacy Policy', url: '/privacy'},
];

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="px-4 py-12 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8">
        <div className="pb-6">
          <a href="/">
            <img
              className="h-20 sm:mb-5 mx-auto"
              src={logo}
              alt="Company name"
            />
          </a>
        </div>

        {
          <nav
            className="flex flex-wrap justify-center -mx-5 -my-2"
            aria-label="Footer"
          >
            {menuItems.map((item, index) => (
              <div key={index} className="px-5 py-2">
                <a
                  href={item?.url}
                  className="text-base text-gray-100 hover:text-green-400"
                >
                  {item?.label}
                </a>
              </div>
            ))}
          </nav>
        }

        <div className="flex justify-center mt-8 space-x-6">
          {navigation.social.map((item, index) => (
            <a
              key={index}
              href={item.href}
              target="_blank" rel="noopener noreferrer"
              className="text-green-400 hover:text-white"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-8 h-8" aria-hidden="true" />
            </a>
          ))}
        </div>

          <p className="max-w-3xl mx-auto mt-8 text-base text-center text-gray-100">
          DISCLAIMER: Our Trader Coaching is designed to help traders in all fundamental aspects of trading and none of our coaches will provide any specific trade recommendations, signals, or any form of investment advice.
          </p>

          <p className="mt-8 text-base text-center text-gray-100">
            &copy; 2024 Trader Coach by <a className="text-green-400 hover:underline" href="https://www.forexanalytix.com" target="_blank" rel="noopener" rel="noreferrer">ForexAnalytix</a>. All rights reserved.
          </p>

      </div>
    </footer>
  )
}
